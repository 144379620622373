import {
  Area,
  AreaChart,
  ChartTooltip,
  CustomTooltip,
  ResponsiveContainer,
  XAxis,
} from "keep-react";

export const ChartTwo = ({ data }) => {
  return (
    <section className="w-full flex justify-center">
      <div className="flex flex-col justify-center w-full max-w-screen-limit h-full">
        <ResponsiveContainer width="100%" height={350}>
          <AreaChart data={data}>
            <defs>
              <linearGradient id="Buying" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#1B4DFF" stopOpacity={0.3} />
                <stop offset="95%" stopColor="#1B4DFF" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="Selling" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#D80027" stopOpacity={0.3} />
                <stop offset="95%" stopColor="#D80027" stopOpacity={0} />
              </linearGradient>
            </defs>
            <Area
              type="natural"
              dataKey="Buying"
              stroke="#1B4DFF"
              strokeWidth={3}
              fillOpacity={1}
              fill="url(#Buying)"
            />
            <Area
              type="natural"
              dataKey="Selling"
              stroke="#D80027"
              strokeWidth={3}
              fillOpacity={1}
              fill="url(#Selling)"
            />

            <XAxis
              className="text-body-4 font-medium text-metal-600"
              dataKey="createdDate"
              stroke="#8897AE"
              strokeWidth={0.5}
              fontSize={12}
            />

            <ChartTooltip content={<CustomTooltip />} />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </section>
  );
};
