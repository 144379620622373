import React from "react";

const ConvertorSkeleton = () => {
  return (
    <section className="w-full h-[380px] md:h-[300px] bg-gray-100 flex justify-center mb-8">
      <div className="flex flex-col justify-center w-full max-w-screen-limit h-full px-5 sm:px-7 md:px-10"></div>
    </section>
  );
};

export default ConvertorSkeleton;
