import React from "react";
import NavBar from "../components/Navbar";
import Footer from "../components/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import Marquee from "react-fast-marquee";

const NewsDetailPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { article } = location.state || {};

  if (!article) {
    return <div>Can't find article</div>;
  }

  return (
    <div className="w-full">
      <NavBar />

      <section className="w-full flex justify-center py-10">
        <div className="w-full max-w-screen-limit h-full mx-auto px-5 sm:px-10">
          <Marquee
            gradient
            speed={180}
            pauseOnHover={true}
            className="w-40 border-t rounded-2xl border-b py-0 overflow-hidden grid"
          >
            {article?.images?.map((image, index) => (
              <div
                key={index}
                className="flex pr-10 flex-col justify-center items-center w-full  mx-auto"
              >
                <img
                  src={image}
                  className="object-fill"
                  alt={`news-img-${index}`}
                />
              </div>
            ))}
          </Marquee>

          <p className="min-h-[30vh] my-5">{article.text}</p>

          <button
            type="button"
            className="text-blue-500 hover:text-white border border-blue-500 hover:bg-blue-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 mt-5 self-end"
            onClick={() => navigate("/news")}
          >
            Go back
          </button>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default NewsDetailPage;
