import React from "react";
import TableRows from "./TableRows";

const SmallTable = ({ tableTitle, lastUpdated, headerData, bodyData }) => {
  return (
    <section className="w-full">
      {/* Table */}
      <div className=" overflow-x-auto rounded-lg">
        <p className="text-gray-700 font-medium py-1">{tableTitle}</p>
        <p className="text-gray-700 font-normal text-xs pb-2">
          Last updated: {lastUpdated}
        </p>
        <table className="mx-auto text-sm w-full table-fixed">
          {/* Table Header */}
          <thead>
            <tr className="font-medium text-gray-700 border-b bg-gray-200">
              {headerData.map((header, index) => (
                <td key={index} className="px-5 py-2 text-center">
                  {header}
                </td>
              ))}
            </tr>
          </thead>

          {/* Table body */}
          <tbody>
            {bodyData.map((data, rowIndex) => (
              <tr
                key={rowIndex}
                className="border-b hover:bg-gray-50 font-medium"
              >
                {Object.entries(data).map(([key, value], colIndex) => (
                  <TableRows key={colIndex} colName={key} value={value} />
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default SmallTable;
