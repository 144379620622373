import React from "react";

const TableRows = ({ colName, value }) => {
  return (
    <td
      className={`px-6 py-2 text-center ${
        colName === "col2"
          ? "text-green-500"
          : colName === "col3"
          ? "text-red-500"
          : ""
      }`}
    >
      <div className="w-full flex justify-center items-center gap-2">
        {colName === "col1" && (
          <img
            src={`./assets/currencies/${value.toLowerCase()}.png`}
            alt={value}
            className="w-[20px]"
          />
        )}
        {colName === "col4" && (
          <img
            src={`./assets/banks/${value.toLowerCase()}.png`}
            alt={value}
            className="w-[20px]"
          />
        )}
        {value}
      </div>
    </td>
  );
};

export default TableRows;
