import React, { useEffect, useState, useRef } from "react";

const Convertor = ({ data }) => {
  //! States
  const [selectedBank, setSelectedBank] = useState("");
  const [availableCurrencies, setAvailableCurrencies] = useState([]);
  const [inputA, setInputA] = useState("");
  const [currencyA, setCurrencyA] = useState("");
  const [convertedToETB, setConvertedToETB] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [bankDropdownOpen, setBankDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const bankDropdownRef = useRef(null);

  //! Use Effects
  useEffect(() => {
    if (selectedBank && data[selectedBank]) {
      setAvailableCurrencies(data[selectedBank]);
      setCurrencyA("");
    } else {
      setAvailableCurrencies([]);
      setCurrencyA("");
    }
  }, [selectedBank, data]);

  useEffect(() => {
    if (currencyA && inputA) {
      handleConversion();
    }
    // eslint-disable-next-line
  }, [currencyA, inputA]);

  //! Functions
  const handleConversion = () => {
    if (!currencyA || !inputA) return;

    const rateA =
      availableCurrencies.find((c) => c["Currency Code"] === currencyA)?.[
        "Cash Buying"
      ] || 0;

    if (rateA) {
      const convertedValue = parseFloat(inputA) * rateA;
      setConvertedToETB(convertedValue.toFixed(4));
    } else {
      setConvertedToETB("0");
    }
  };

  const handleCurrencyChange = (currencyCode) => {
    setCurrencyA(currencyCode);
    setDropdownOpen(false);

    if (!inputA) {
      setInputA("1");
    }
  };

  const handleBankChange = (bank) => {
    setSelectedBank(bank);
    setBankDropdownOpen(false);
    setConvertedToETB("");
  };

  return (
    <section className="w-full h-[380px] md:h-[300px] bg-gray-100 flex justify-center mb-8">
      <div className="flex flex-col justify-center w-full max-w-screen-limit h-full px-5 sm:px-7 md:px-10">
        <div className="">
          <p className="font-semibold text-lg md:text-xl mb-5">
            Currency Convertor
          </p>

          <div className="flex flex-col gap-3 md:mx-10">
            {/* Bank Selection */}
            <div>
              <p className="text-sm text-gray-500 mb-1">Select Bank</p>
              <div className="relative w-full">
                <button
                  id="dropdown-bank-button"
                  onClick={() => setBankDropdownOpen(!bankDropdownOpen)}
                  className="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 gap-2 text-sm font-medium text-center text-gray-900 bg-gray-50 border border-gray-300 rounded-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 w-full"
                  type="button"
                >
                  {/* Display selected bank */}
                  <img
                    src={`./assets/banks/${selectedBank.toLowerCase()}.png`}
                    alt={selectedBank}
                    className="w-5"
                  />
                  {selectedBank || "Select Bank"}
                  <svg
                    className="w-2.5 h-2.5 ml-auto"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 4 4 4-4"
                    />
                  </svg>
                </button>

                {bankDropdownOpen && (
                  <div
                    ref={bankDropdownRef}
                    id="dropdown-bank"
                    className="absolute z-30 w-full bg-white divide-y divide-gray-100 rounded-lg shadow max-h-[10rem] overflow-y-scroll mt-2"
                    style={{
                      top: dropdownRef.current?.offsetHeight + 5,
                      right: 0,
                    }}
                  >
                    <ul
                      className="py-2 text-sm text-gray-700"
                      aria-labelledby="dropdown-bank-button"
                    >
                      {Object.keys(data)
                        .filter((bank) => data[bank].length > 0)
                        .sort((a, b) => a.localeCompare(b))
                        .map((bank) => (
                          <li key={bank}>
                            <button
                              type="button"
                              className="inline-flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                              onClick={() => handleBankChange(bank)}
                            >
                              <div className="inline-flex items-center gap-2">
                                <img
                                  src={`./assets/banks/${bank.toLowerCase()}.png`}
                                  alt={bank}
                                  className="w-5"
                                />
                                {bank}
                              </div>
                            </button>
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>

            <div className="flex flex-col md:flex-row justify-between md:items-center gap-5">
              {/* From Selection*/}
              <div className="flex-grow">
                <p className="text-sm text-gray-500 mb-1">From</p>
                <div className="relative w-full flex">
                  <div className="w-full">
                    <div className="absolute inset-y-0 start-0 top-0 flex items-center ps-3.5 pointer-events-none">
                      <svg
                        className="w-4 h-4 text-gray-500"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 16"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 2a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1M2 5h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1Zm8 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
                        />
                      </svg>
                    </div>
                    {/* Amount */}
                    <input
                      type="number"
                      id="currency-input"
                      className="block p-2.5 w-full z-20 ps-10 text-sm text-gray-900 bg-gray-50 rounded-s-lg border-e-gray-50 border-e-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                      placeholder="Enter amount"
                      required
                      value={inputA}
                      onChange={(e) => setInputA(e.target.value)}
                      disabled={!currencyA}
                    />
                  </div>

                  <button
                    id="dropdown-currency-button"
                    ref={dropdownRef}
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                    className="flex-shrink-0 w-32 z-10 inline-flex items-center py-2.5 px-4 gap-2 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-e-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100"
                    type="button"
                  >
                    {/* Display selected currency */}
                    <img
                      src={`./assets/currencies/${currencyA.toLowerCase()}.png`}
                      alt={currencyA}
                      className="w-5"
                    />
                    {currencyA || "Select"}
                    <svg
                      className="w-2.5 h-2.5 ms-2.5"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 10 6"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 4 4 4-4"
                      />
                    </svg>
                  </button>

                  {dropdownOpen && (
                    <div
                      id="dropdown-currency"
                      className="absolute z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-32 max-h-[10rem] overflow-y-scroll mt-2"
                      style={{
                        top: dropdownRef.current?.offsetHeight + 5,
                        right: 0,
                      }}
                    >
                      <ul
                        className="py-2 text-sm text-gray-700"
                        aria-labelledby="dropdown-currency-button"
                      >
                        {availableCurrencies.map((currency) => (
                          <li key={currency["Currency Code"]}>
                            <button
                              type="button"
                              className="inline-flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                              onClick={() =>
                                handleCurrencyChange(currency["Currency Code"])
                              }
                            >
                              <div className="inline-flex items-center gap-2">
                                <img
                                  src={`./assets/currencies/${currency[
                                    "Currency Code"
                                  ].toLowerCase()}.png`}
                                  alt={currency["Currency Code"]}
                                  className="w-5"
                                />
                                {currency["Currency Code"]}
                              </div>
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>

              {/* To value */}
              <div className="flex-grow">
                <p className="text-sm text-gray-500 mb-1">To</p>
                <div className="relative w-full flex">
                  <div className="w-full">
                    <div className="absolute inset-y-0 start-0 top-0 flex items-center ps-3.5 pointer-events-none">
                      <svg
                        className="w-4 h-4 text-gray-500"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 16"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 2a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1M2 5h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1Zm8 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
                        />
                      </svg>
                    </div>
                    {/* Converted Amount */}
                    <input
                      type="text"
                      id="converted-input"
                      className="block p-2.5 w-full z-20 ps-10 text-sm text-gray-900 bg-gray-50 rounded-s-lg border-e-gray-50 border-e-2 border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                      placeholder="0.00"
                      readOnly
                      value={convertedToETB}
                    />
                  </div>

                  <button
                    id="dropdown-currency-button"
                    className="flex-shrink-0 inline-flex items-center w-32 py-2.5 px-4 gap-2 text-sm font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-e-lg hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100"
                    type="button"
                    disabled
                  >
                    {/* Display "ETB" */}
                    <img
                      src={`./assets/currencies/etb.png`} // Path to the ETB currency image
                      alt="ETB"
                      className="w-5"
                    />
                    ETB
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* ! currency value */}
          <div className="mt-5 flex justify-center items-center w-full">
            <p className="text-sm text-gray-600">
              1 {!currencyA && " currency "} {currencyA} ={" "}
              {availableCurrencies.find(
                (c) => c["Currency Code"] === currencyA
              )?.["Cash Buying"] || "0.00"}{" "}
              ETB in {selectedBank} bank
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Convertor;
