import axios from "axios";
import React, { useEffect, useState } from "react";
import NavBar from "../components/Navbar";
import Banner from "../components/Banner";
import CardContainer from "../components/CardContainer";
import OverallExchangeGraph from "../components/OverallExchangeGraph";
import Footer from "../components/Footer";
import CardContainerSkeletion from "../components/skeletons/CardContainerSkeletion";
import OverallExchangeSkeleton from "../components/skeletons/OverallExchangeSkeleton";

const TrendsPage = () => {
  //! Constants
  const API_KEY = process.env.REACT_APP_API_KEY;

  //! States
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState({});

  //! Effects
  useEffect(() => {
    //// eslint-disable-next-line
    async function fetchData() {
      try {
        setLoading(true);
        const response = await axios.get(API_KEY);
        setData(response.data);
        setError(null);
      } catch (err) {
        setError(err.message);
        setData(null);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [API_KEY]);

  //! Functions
  function getTopBuyingRates(data) {
    const currencies = ["USD", "GBP", "EUR", "AED", "JPY"];
    const currencyNames = {
      USD: "US Dollar",
      GBP: "Pound",
      EUR: "Euro",
      AED: "Dirham",
      JPY: "Yen",
    };
    const topRates = [];

    currencies.forEach((currency) => {
      let topRate = null;

      for (const bank in data) {
        const rates = data[bank];

        if (Array.isArray(rates) && rates[0] && rates[0]["Currency Code"]) {
          // eslint-disable-next-line
          rates.forEach((rate) => {
            if (rate["Currency Code"] === currency) {
              if (
                !topRate ||
                parseFloat(rate["Cash Buying"]) >
                  parseFloat(topRate.buyingPrice)
              ) {
                topRate = {
                  currencyName: currencyNames[currency],
                  currencyCode: rate["Currency Code"],
                  buyingPrice: rate["Cash Buying"],
                  bankName: bank,
                };
              }
            }
          });
        }
      }

      if (topRate) {
        topRate.imageUrl = `./assets/currencies/${currency.toLowerCase()}.png`;
        topRates.push(topRate);
      }
    });

    return topRates;
  }

  return (
    <div className="w-full">
      <NavBar />

      <div className="min-h-[calc(100vh-68px)]">
        <Banner
          isMain={false}
          bigText1="Exchange Insights"
          bigText2=""
          miniText="Compare rates, make smart decisions"
        />

        {loading && !error && (
          <>
            <CardContainerSkeletion />
            <OverallExchangeSkeleton />
          </>
        )}
        {!loading && error && <p>{error}</p>}
        {!loading && !error && (
          <>
            <CardContainer
              title="Highest Buying Currencies"
              cardData={getTopBuyingRates(data)}
              purpose="buying"
            />

            <OverallExchangeGraph title1="Overall Exchange Graph" />
          </>
        )}
      </div>

      <Footer />
    </div>
  );
};

export default TrendsPage;
