export const banksInfo = {
  Abay: {
    bankLogo: "./assets/banks/abay.png",
    bankName: "Abay Bank",
    bankShortName: "Abay",
  },
  Addis: {
    bankLogo: "./assets/banks/addis.png",
    bankName: "Addis Bank",
    bankShortName: "Addis",
  },
  Ahadu: {
    bankLogo: "./assets/banks/ahadu.png",
    bankName: "Ahadu Bank",
    bankShortName: "Ahadu",
  },
  Amhara: {
    bankLogo: "./assets/banks/amhara.png",
    bankName: "Amhara Bank",
    bankShortName: "Amhara",
  },
  Anbesa: {
    bankLogo: "./assets/banks/anbesa.png",
    bankName: "Lion International Bank",
    bankShortName: "Anbesa",
  },
  Awash: {
    bankLogo: "./assets/banks/awash.png",
    bankName: "Awash International Bank",
    bankShortName: "Awash",
  },
  Berhan: {
    bankLogo: "./assets/banks/berhan.png",
    bankName: "Berhan Bank",
    bankShortName: "Berhan",
  },
  BOA: {
    bankLogo: "./assets/banks/boa.png",
    bankName: "Bank of Abyssinia",
    bankShortName: "BOA",
  },
  Bunna: {
    bankLogo: "./assets/banks/bunna.png",
    bankName: "Bunna Bank",
    bankShortName: "Bunna",
  },
  CBE: {
    bankLogo: "./assets/banks/cbe.png",
    bankName: "Commercial Bank of Ethiopia",
    bankShortName: "CBE",
  },
  Coop: {
    bankLogo: "./assets/banks/coop.png",
    bankName: "Cooperative Bank of Oromia",
    bankShortName: "COOP",
  },
  Dashen: {
    bankLogo: "./assets/banks/dashen.png",
    bankName: "Dashen Bank",
    bankShortName: "Dashen",
  },
  Enat: {
    bankLogo: "./assets/banks/enat.png",
    bankName: "Enat Bank",
    bankShortName: "Enat",
  },
  Gadaa: {
    bankLogo: "./assets/banks/gadaa.png",
    bankName: "Gadaa Bank",
    bankShortName: "Gadaa",
  },
  Global: {
    bankLogo: "./assets/banks/global.png",
    bankName: "Global Bank",
    bankShortName: "Global",
  },
  Gohbet: {
    bankLogo: "./assets/banks/gohbet.png",
    bankName: "Gohbet Bank",
    bankShortName: "Gohbet",
  },
  Hibret: {
    bankLogo: "./assets/banks/hibret.png",
    bankName: "Hibret Bank",
    bankShortName: "Hibret",
  },
  Hijra: {
    bankLogo: "./assets/banks/hijra.png",
    bankName: "Hijra Bank",
    bankShortName: "Hijra",
  },
  NIB: {
    bankLogo: "./assets/banks/nib.png",
    bankName: "Nib International Bank",
    bankShortName: "Nib",
  },
  Oromia: {
    bankLogo: "./assets/banks/oromia.png",
    bankName: "Oromia Bank",
    bankShortName: "Oromia",
  },
  Siinqee: {
    bankLogo: "./assets/banks/siinqee.png",
    bankName: "Siinqee Bank",
    bankShortName: "Siinqee",
  },
  Tsedey: {
    bankLogo: "./assets/banks/tsedey.png",
    bankName: "Tsedey Bank",
    bankShortName: "Tsedey",
  },
  Tsehay: {
    bankLogo: "./assets/banks/tsehay.png",
    bankName: "Tsehay Bank",
    bankShortName: "Tsehay",
  },
  Wegagen: {
    bankLogo: "./assets/banks/wegagen.png",
    bankName: "Wegagen Bank",
    bankShortName: "Wegagen",
  },
  Zamzam: {
    bankLogo: "./assets/banks/zamzam.png",
    bankName: "Zamzam Bank",
    bankShortName: "Zamzam",
  },
  Zemen: {
    bankLogo: "./assets/banks/zemen.png",
    bankName: "Zemen Bank",
    bankShortName: "Zemen",
  },
};
