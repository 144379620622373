import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { EtxContext } from "../../context/etxContext";
import { banksInfo } from "../../data/BankInformations";
import { currencyInfo } from "../../data/CurrencyInfo";
import axios from "axios";

const AddRate = () => {
  //! Consts
  const API_KEY = process.env.REACT_APP_ADD_CURRENCY_API_KEY;
  const { setActivePage } = useContext(EtxContext);

  const defaultCurrencyName = "United Arab Emirates Dirham";
  const initialCurrency = currencyInfo.find(
    (currency) => currency.currencyName === defaultCurrencyName
  );

  //! States
  const [bankName, setBankName] = useState("");
  const [currencyName, setCurrencyName] = useState(defaultCurrencyName);
  const [currencyCode, setCurrencyCode] = useState(
    initialCurrency ? initialCurrency.currencyCode : ""
  );
  const [buyingPrice, setBuyingPrice] = useState("");
  const [sellingPrice, setSellingPrice] = useState("");
  const [selectedDate, setSelectedDate] = useState("");

  //! Effects
  useEffect(() => {
    const selectedCurrency = currencyInfo.find(
      (currency) => currency.currencyName === currencyName
    );
    if (selectedCurrency) {
      setCurrencyCode(selectedCurrency.currencyCode);
    }
  }, [currencyName]);

  //! Functions
  const handleCurrencyNameChange = (event) => {
    const selectedCurrencyName = event.target.value;
    setCurrencyName(selectedCurrencyName);
  };

  const handleBankNameChange = (event) => {
    setBankName(event.target.value);
  };

  const handleBuyingPriceChange = (event) => {
    setBuyingPrice(event.target.value);
  };

  const handleSellingPriceChange = (event) => {
    setSellingPrice(event.target.value);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const currentDate = new Date();

    const [year, month, day] = selectedDate.split("-");
    const combinedDate = new Date(
      year,
      month - 1,
      day,
      currentDate.getHours() + 3,
      currentDate.getMinutes(),
      currentDate.getSeconds(),
      currentDate.getMilliseconds()
    );

    const isoFormattedDate = combinedDate.toISOString();

    const formData = {
      bankName: event.target.bankName.value,
      currencyCode: currencyCode,
      currencyBuying: parseFloat(event.target.buyingPrice.value),
      currencySelling: parseFloat(event.target.sellingPrice.value),
      createdDate: isoFormattedDate,
    };

    try {
      const response = await axios.post(API_KEY, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      console.log("Form Data Submitted:", response.data);
      console.log(formData);

      setBuyingPrice("");
      setSellingPrice("");
      setSelectedDate("");
    } catch (error) {
      console.log(formData);
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div className="p-5 sm:p-7 bg-white rounded-lg shadow-sm">
      {/* Page title */}
      <p className="text-xl font-semibold">Add Rate Page</p>

      {/* Breadcrumbs */}
      <nav className="flex mt-2" aria-label="Breadcrumb">
        <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
          <li className="inline-flex items-center">
            <Link
              to="/"
              className="inline-flex items-center text-xs font-normal text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white"
            >
              <svg
                className="w-3 h-3 me-2.5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
              </svg>
              Home
            </Link>
          </li>

          <li>
            <div className="flex items-center">
              <svg
                className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
              <button
                onClick={() => setActivePage("currency")}
                className="ms-1 text-xs font-normal text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white"
              >
                Currencies
              </button>
            </div>
          </li>

          <li aria-current="page">
            <div className="flex items-center">
              <svg
                className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
              <span className="ms-1 text-xs font-normal text-gray-500 md:ms-2 dark:text-gray-400">
                Add Rate
              </span>
            </div>
          </li>
        </ol>
      </nav>

      {/* Form */}
      <div className="h-[calc(100%-4rem)] mt-2">
        <section className="mt-7 h-[calc(100%-2rem)] w-full flex flex-col">
          {/* <p className="font-semibold">New Currency Form</p> */}

          <form className="mx-0 sm:mx-5 mt-3 flex-grow" onSubmit={handleSubmit}>
            {/* bankName */}
            <div className="mb-5">
              <label
                htmlFor="bankName"
                className="block mb-2 text-xs font-normal text-gray-600"
              >
                Bank Name
              </label>
              <select
                id="bankName"
                name="bankName"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                value={bankName}
                onChange={handleBankNameChange}
                required
              >
                {Object.keys(banksInfo).map((key) => (
                  <option key={key} value={key}>
                    {banksInfo[key].bankName}
                  </option>
                ))}
              </select>
            </div>

            {/* Currency Name */}
            <div className="mb-5">
              <label
                htmlFor="currencyName"
                className="block mb-2 text-xs font-normal text-gray-600"
              >
                Currency Name
              </label>
              <select
                id="currencyName"
                name="currencyName"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2"
                value={currencyName}
                onChange={handleCurrencyNameChange}
                required
              >
                {currencyInfo.map((currency, index) => (
                  <option key={index} value={currency.currencyName}>
                    {currency.currencyName}
                  </option>
                ))}
              </select>
            </div>

            {/* Currency Code */}
            <div className="mb-5">
              <label
                htmlFor="currencyCode"
                className="block mb-2 text-xs font-normal text-gray-600"
              >
                Currency Code
              </label>
              <input
                type="text"
                id="currencyCode"
                name="currencyCode"
                className="block mb-5 bg-gray-100 border border-gray-300 text-gray-900 text-sm rounded-lg w-full p-2 cursor-not-allowed"
                value={currencyCode}
                disabled
              />
            </div>

            <div className="flex items-center justify-between gap-5">
              {/* Buying Price */}
              <div className="mb-5 flex-grow">
                <label
                  htmlFor="buying"
                  className="block mb-2 text-xs font-normal text-gray-600"
                >
                  Buying Price
                </label>
                <input
                  type="number"
                  id="buying"
                  name="buyingPrice"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2"
                  value={buyingPrice}
                  onChange={handleBuyingPriceChange}
                  required
                />
              </div>

              {/* Selling Price */}
              <div className="mb-5 flex-grow">
                <label
                  htmlFor="selling"
                  className="block mb-2 text-xs font-normal text-gray-600"
                >
                  Selling Price
                </label>
                <input
                  type="number"
                  id="selling"
                  name="sellingPrice"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2"
                  value={sellingPrice}
                  onChange={handleSellingPriceChange}
                  required
                />
              </div>
            </div>

            {/* Date */}
            <div className="relative flex-grow mb-5">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  className="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                </svg>
              </div>
              <input
                type="date"
                id="datepicker"
                name="date"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
                value={selectedDate}
                onChange={handleDateChange}
                required
              />
            </div>

            {/* Submit button */}
            <button
              type="submit"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full px-5 py-2 mt-2 text-center mb-5"
            >
              Submit
            </button>
          </form>
        </section>
      </div>
    </div>
  );
};

export default AddRate;
