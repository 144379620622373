import { createContext, useState } from "react";

export const EtxContext = createContext(null);

export const ContextProvider = (props) => {
  //! STATES
  const [activePage, setActivePage] = useState("dashboard");

  //! USE EFFECTS

  //! FUNCTIONS

  // things we want to pass
  const contextValue = {
    activePage,
    setActivePage,
  };

  return (
    <EtxContext.Provider value={contextValue}>
      {props.children}
    </EtxContext.Provider>
  );
};
