import React from "react";
import Card from "./Card";

const CardContainer = ({ title, lastUpdated, cardData, purpose }) => {
  return (
    <section className="w-full flex justify-center">
      <div className="w-full flex flex-col justify-center max-w-screen-limit h-full px-5 sm:px-5">
        {/* Title */}
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
          <div>
            <p className="font-semibold text-xl">{title}</p>
          </div>
          <div>
            {/* <p className="text-xs">Last updated: {lastUpdated}</p> */}
          </div>
        </div>

        {/* Cards */}
        <div className="carousel rounded-box w-full gap-5">
          {cardData.map((data, index) => (
            <div className="carousel-item" key={index}>
              <Card cardData={data} purpose={purpose} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CardContainer;
