import React from "react";
import TableSkeleton from "./TableSkeleton";

const HighBuyingAndLowSellingTableSkeleton = () => {
  return (
    <section className="w-full flex justify-center mb-5">
      <div className="w-full flex flex-col justify-between gap-5 max-w-screen-limit h-full">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
          <TableSkeleton />
          <TableSkeleton />
        </div>
      </div>
    </section>
  );
};

export default HighBuyingAndLowSellingTableSkeleton;
