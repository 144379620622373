import React from "react";
import CardSkeletion from "./CardSkeletion";

const CardContainerSkeletion = () => {
  return (
    <section className="w-full flex justify-center">
      <div className="w-full flex flex-col justify-center max-w-screen-limit h-full px-5 sm:px-5">
        {/* Title */}
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
          <div>
            {/* Title */}
            <div className="h-7 w-60 bg-gray-200 rounded-full animate-pulse"></div>
          </div>
          <div>
            {/* Date */}
            <div className="h-5 w-52 bg-gray-200 rounded-full animate-pulse"></div>
          </div>
        </div>

        {/* Cards */}
        <div className="carousel rounded-box w-full">
          <div className="carousel-item flex gap-5">
            <CardSkeletion />
            <CardSkeletion />
            <CardSkeletion />
            <CardSkeletion />
            <CardSkeletion />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CardContainerSkeletion;
