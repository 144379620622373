import axios from "axios";
import React, { useEffect, useState } from "react";
import NavBar from "../components/Navbar";
import Banner from "../components/Banner";
import HighBuyingAndLowSellingTableSkeleton from "../components/skeletons/HighBuyingAndLowSellingTableSkeleton";
import Convertor from "../components/Convertor";
import AllBanksTable from "../components/AllBanksTable";
import Footer from "../components/Footer";
import HighBuyingAndLowSellingTable from "../components/HighBuyingAndLowSellingTable";
import ConvertorSkeleton from "../components/skeletons/ConvertorSkeleton";
import AllBanksTableSkeleton from "../components/skeletons/AllBanksTableSkeleton";
import { banksInfo } from "../data/BankInformations";

const HomePage = () => {
  //! Constants
  const API_KEY = process.env.REACT_APP_API_KEY;

  //! States
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState({});

  //! Effects
  useEffect(() => {
    //// eslint-disable-next-line
    async function fetchData() {
      try {
        setLoading(true);
        const response = await axios.get(API_KEY);
        setData(response.data);
        setError(null);
      } catch (err) {
        setError(err.message);
        setData(null);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [API_KEY]);

  //! Functions
  function formatDateToReadable(dateString) {
    const date = new Date(dateString);
    return date.toLocaleString("en-GB", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  }

  // function transformAllBankData(data) {
  //   const transformedData = [];

  //   const bks = banksInfo;

  //   for (const bank in data) {
  //     const rates = data[bank];

  //     if (Array.isArray(rates) && rates[0] && rates[0]["Currency Code"]) {
  //       const exchangeRates = rates.map((rate) => ({
  //         col1: rate["Currency Code"],
  //         col2: rate["Cash Buying"] !== "N/A" ? rate["Cash Buying"] : "-",
  //         col3: rate["Cash Selling"] !== "N/A" ? rate["Cash Selling"] : "-",
  //       }));

  //       const createdAt = rates[0]["Created At"];

  //       transformedData.push({
  //         bankLogo: bks[bank].bankLogo,
  //         bankName: bks[bank].bankName,
  //         bankShortName: bks[bank].bankShortName,
  //         lastUpdated: formatDateToReadable(createdAt) || "",
  //         exchangeRates: exchangeRates,
  //       });
  //     }
  //   }

  //   // Sort the transformed data alphabetically by bankName
  //   transformedData.sort((a, b) => a.bankName.localeCompare(b.bankName));

  //   return transformedData;
  // }

  function transformAllBankData(data) {
    const transformedData = [];

    const bks = banksInfo;

    for (const bank in data) {
      const rates = data[bank];

      // Check if the data is in the expected format (i.e., the bank has rates)
      if (Array.isArray(rates)) {
        // Filter out invalid entries where Currency Code or Cash Buying/Selling is missing or invalid
        const validRates = rates.filter(
          (rate) =>
            rate["Currency Code"] &&
            rate["Cash Buying"] !== "0.0000" &&
            rate["Cash Selling"] !== "0.0000"
        );

        // Only proceed if there are valid rates
        if (validRates.length > 0) {
          // Map valid rates to the desired format
          const exchangeRates = validRates.map((rate) => ({
            col1: rate["Currency Code"],
            col2: rate["Cash Buying"] !== "N/A" ? rate["Cash Buying"] : "-",
            col3: rate["Cash Selling"] !== "N/A" ? rate["Cash Selling"] : "-",
          }));

          const createdAt = validRates[0]["Created At"]; // Use the first valid record's timestamp

          transformedData.push({
            bankLogo: bks[bank].bankLogo,
            bankName: bks[bank].bankName,
            bankShortName: bks[bank].bankShortName,
            lastUpdated: formatDateToReadable(createdAt) || "",
            exchangeRates: exchangeRates,
          });
        }
      }
    }

    // Sort the transformed data alphabetically by bankName
    transformedData.sort((a, b) => a.bankName.localeCompare(b.bankName));

    return transformedData;
  }

  function getTopBuying(data) {
    const currencies = ["USD", "GBP", "EUR", "AED"];
    const topRates = [];

    currencies.forEach((currency) => {
      let topRate = null;

      for (const bank in data) {
        const rates = data[bank];

        if (Array.isArray(rates) && rates[0] && rates[0]["Currency Code"]) {
          // eslint-disable-next-line
          rates.forEach((rate) => {
            if (rate["Currency Code"] === currency) {
              if (
                !topRate ||
                parseFloat(rate["Cash Buying"]) >
                  parseFloat(topRate.buyingPrice)
              ) {
                topRate = {
                  currencyCode: rate["Currency Code"],
                  buyingPrice: rate["Cash Buying"],
                  bankName: bank,
                };
              }
            }
          });
        }
      }

      if (topRate) {
        topRates.push({
          col1: topRate.currencyCode,
          col2: topRate.buyingPrice,
          col4: topRate.bankName,
        });
      }
    });

    return topRates;
  }

  function getLowestSelling(data) {
    const currencies = ["USD", "GBP", "EUR", "AED"];
    const lowestRates = [];

    currencies.forEach((currency) => {
      let lowestRate = null;

      for (const bank in data) {
        const rates = data[bank];

        if (Array.isArray(rates) && rates[0] && rates[0]["Currency Code"]) {
          // eslint-disable-next-line
          rates.forEach((rate) => {
            const sellingPrice = parseFloat(rate["Cash Selling"]);

            if (rate["Currency Code"] === currency && sellingPrice > 0) {
              if (
                !lowestRate ||
                sellingPrice < parseFloat(lowestRate.sellingPrice)
              ) {
                lowestRate = {
                  currencyCode: rate["Currency Code"],
                  sellingPrice: rate["Cash Selling"],
                  bankName: bank,
                };
              }
            }
          });
        }
      }

      if (lowestRate) {
        lowestRates.push({
          col1: lowestRate.currencyCode,
          col3: lowestRate.sellingPrice,
          col4: lowestRate.bankName,
        });
      }
    });

    return lowestRates;
  }

  return (
    <div className="w-full">
      <NavBar />
      <Banner
        isMain={true}
        bigText1="ETX - Your Hub for Ethiopian"
        bigText2="Exchange Rates"
        miniText="Stay updated with the latest exchange rates across all Ethiopian banks"
      />

      {/* On load */}
      {loading && !error && (
        <>
          <HighBuyingAndLowSellingTableSkeleton />
          <ConvertorSkeleton />
          <AllBanksTableSkeleton />
        </>
      )}

      {/* On error */}
      {!loading && error && (
        <p className="text-center text-red-600 mb-10">{error}</p>
      )}

      {/* On success */}
      {!loading && !error && (
        <>
          <HighBuyingAndLowSellingTable
            data={data}
            getTopBuying={getTopBuying}
            getLowestSelling={getLowestSelling}
          />
          <Convertor data={data} />
          <AllBanksTable allBanksData={transformAllBankData(data)} />
        </>
      )}
      <Footer />
    </div>
  );
};

export default HomePage;
