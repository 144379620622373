import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { EtxContext } from "../../context/etxContext";
import { Space, Table, Tag } from "antd";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const CurrencyPage = () => {
  const { setActivePage } = useContext(EtxContext);

  //! Constants
  const API_KEY = process.env.REACT_APP_API_KEY;

  const columns = [
    {
      title: "Currency Name",
      dataIndex: "currencyName",
      key: "currencyName",
      render: (_, { currencyCode }) => (
        <div className="flex items-center justify-start gap-2">
          <img
            src={`./assets/currencies/${currencyCode}.png`}
            alt=""
            className="w-6"
          />
          <p>{currencyCode}</p>
        </div>
      ),
    },
    {
      title: "Buying Price",
      dataIndex: "buyingPrice",
      key: "buyingPrice",
      render: (_, { buyingPrice }) => (
        <Tag color="green" className="px-5">
          {buyingPrice}
        </Tag>
      ),
      sorter: (a, b) => a.buyingPrice - b.buyingPrice,
    },
    {
      title: "Selling Price",
      dataIndex: "sellingPrice",
      key: "sellingPrice",
      render: (_, { buyingPrice }) => (
        <Tag color="volcano" className="px-5">
          {buyingPrice}
        </Tag>
      ),
      sorter: (a, b) => a.sellingPrice - b.sellingPrice,
    },
    {
      title: "Bank Name",
      dataIndex: "bankName",
      key: "bankName",
      render: (_, { bankName }) => (
        <div className="flex items-center justify-start gap-2">
          <img
            src={`./assets/banks/${bankName.toLowerCase()}.png`}
            alt=""
            className="w-5"
          />
          <p>{bankName}</p>
        </div>
      ),
    },
    {
      title: "",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <a href="/" className="hover:text-green-400 text-gray-400">
            <EditIcon style={{ fontSize: "19px" }} />
          </a>
          <a href="/" className="hover:text-red-600 text-gray-400">
            <DeleteForeverIcon style={{ fontSize: "20px" }} />
          </a>
        </Space>
      ),
    },
  ];

  //! Use states
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState({});

  //! Use effects
  useEffect(() => {
    //// eslint-disable-next-line
    async function fetchData() {
      try {
        setLoading(true);
        const response = await axios.get(API_KEY);
        setData(response.data);
        setError(null);
      } catch (err) {
        setError(err.message);
        setData(null);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [API_KEY]);

  //! Functions
  function transformData(data) {
    let keyCounter = 1;
    const dataSource = [];

    Object.keys(data).forEach((bankName) => {
      const bankData = data[bankName];

      if (Array.isArray(bankData) && bankData.length > 0) {
        bankData.forEach((entry) => {
          if (
            entry["Currency Code"] &&
            entry["Currency Name"] &&
            entry["Cash Buying"] &&
            entry["Cash Selling"]
          ) {
            dataSource.push({
              key: keyCounter.toString(),
              currencyName: entry["Currency Name"],
              currencyCode: entry["Currency Code"],
              buyingPrice: parseFloat(entry["Cash Buying"]),
              sellingPrice: parseFloat(entry["Cash Selling"]),
              bankName: bankName,
            });
            keyCounter++;
          }
        });
      }
    });

    return dataSource;
  }

  return (
    <div className="p-5 sm:p-7 bg-white rounded-lg shadow-sm h-full">
      <div className="flex justify-between items-center">
        {/* Title + Breadcrubms */}
        <div className="flex flex-col">
          <p className="text-xl font-semibold">Currency Page</p>

          <nav className="flex mt-2" aria-label="Breadcrumb">
            <ol className="inline-flex items-center space-x-1 md:space-x-2">
              <li className="inline-flex items-center">
                <Link
                  to="/"
                  className="inline-flex items-center text-xs font-normal text-gray-700 hover:text-blue-600"
                >
                  <svg
                    className="w-3 h-3 me-2.5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                  </svg>
                  Home
                </Link>
              </li>
              <li aria-current="page">
                <div className="flex items-center">
                  <svg
                    className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 9 4-4-4-4"
                    />
                  </svg>
                  <span className="ms-1 text-xs font-normal text-gray-500 md:ms-2 dark:text-gray-400">
                    Currency
                  </span>
                </div>
              </li>
            </ol>
          </nav>
        </div>

        {/* button */}
        <div>
          <button
            onClick={() => setActivePage("addCurrency")}
            className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 font-medium rounded-lg text-xs sm:text-sm px-2 sm:px-5 py-1.5 sm:py-2 text-center"
          >
            Add Rate
          </button>
        </div>
      </div>

      <div className="h-[calc(100%-4rem)] mt-2">
        {/* Table data */}
        <section className="mt-7 h-[calc(100%-2rem)] overflow-auto">
          {loading && !error && <p>Loading...</p>}
          {!loading && error && <p>{error}</p>}
          {!loading && !error && (
            <Table dataSource={transformData(data)} columns={columns} />
          )}
        </section>
      </div>
    </div>
  );
};

export default CurrencyPage;
