import React from "react";

const CardSkeletion = () => {
  return (
    <div className="my-5 w-[300px] sm:w-[330px] h-[150px] rounded-xl flex items-center justify-between border shadow-lg">
      {/* Card */}
      <div className="flex flex-col justify-center w-full h-full px-4 py-3">
        <div className="flex items-center gap-2 xl:gap-3">
          {/* Image */}
          <div className="h-10 w-12 bg-gray-200 rounded-full animate-pulse p-3"></div>

          <div className="flex flex-col justify-center w-full gap-2">
            {/* Currency Name */}
            <div className="h-4 w-24 bg-gray-200 rounded-full animate-pulse"></div>
            {/* Currency Code */}
            <div className="h-3 w-10 bg-gray-200 rounded-full animate-pulse"></div>
          </div>
        </div>

        {/* Buying Price */}
        <div className="h-5 w-20 bg-gray-200 rounded-full my-3 animate-pulse"></div>

        {/* Bank Name */}
        <div className="h-3 w-10 bg-gray-200 rounded-full animate-pulse"></div>
      </div>

      {/* Graph */}
      <div className="w-full h-full px-3">
        {/* Chart */}
        <div className="h-28 w-28 my-5 bg-gray-200 animate-pulse"></div>
      </div>
    </div>
  );
};

export default CardSkeletion;
