import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PaidIcon from "@mui/icons-material/Paid";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import DashboardPage from "./DashboardPages/DashboardPage";
import CurrencyPage from "./DashboardPages/CurrencyPage";
import AddCurrency from "./DashboardPages/AddRate";
import { EtxContext } from "../context/etxContext";

const AdminPage = () => {
  const { activePage, setActivePage } = useContext(EtxContext);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const renderActivePage = () => {
    if (activePage === "dashboard") return <DashboardPage />;
    if (activePage === "currency") return <CurrencyPage />;
    if (activePage === "addCurrency") return <AddCurrency />;
    return null;
  };

  return (
    <>
      {/* Sidebar button */}
      <button
        data-drawer-target="logo-sidebar"
        data-drawer-toggle="logo-sidebar"
        aria-controls="logo-sidebar"
        type="button"
        className="inline-flex items-center p-2 my-2 ms-3 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-gray-200"
        onClick={toggleSidebar}
      >
        <span className="sr-only">Open sidebar</span>
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          ></path>
        </svg>
      </button>

      {/* Overlay */}
      {isSidebarOpen && (
        <div
          onClick={toggleSidebar}
          className="lg:hidden fixed inset-0 bg-black opacity-50 z-30 h-full w-full"
        ></div>
      )}

      {/* Sidebar */}
      <aside
        id="logo-sidebar"
        className={`fixed top-0 left-0 z-40 w-64 h-screen transition-transform ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } lg:translate-x-0`}
        aria-label="Sidebar"
      >
        <div className="h-full px-3 py-8 overflow-y-auto bg-[#191f2f] shadow-lg shadow-black">
          <Link to="/" className="flex items-center ps-2.5 mb-5">
            <span className="self-center text-xl text-white font-semibold whitespace-nowrap">
              Ethiopian Exchange
            </span>
          </Link>

          <ul className="space-y-2 font-medium">
            <li>
              <button
                onClick={() => {
                  setActivePage("dashboard");
                  toggleSidebar();
                }}
                className="flex items-center w-full p-2 text-white rounded-lg hover:bg-[#474c59]"
              >
                <DashboardIcon style={{ fontSize: "18px" }} />
                <span
                  className={`ms-3  ${
                    activePage === "dashboard"
                      ? "font-semibold text-sm"
                      : "font-normal text-[13px]"
                  }`}
                >
                  Dashboard
                </span>
              </button>
            </li>

            <li>
              <button
                onClick={() => {
                  setActivePage("currency");
                  toggleSidebar();
                }}
                className="flex items-center w-full  p-2 text-white rounded-lg hover:bg-[#474c59]"
              >
                <PaidIcon style={{ fontSize: "18px" }} />
                <span
                  className={`ms-3  ${
                    activePage === "currency"
                      ? "font-semibold text-sm"
                      : "font-normal text-[13px]"
                  }`}
                >
                  Currencies
                </span>
              </button>
            </li>

            <li>
              <a
                href="/"
                className="flex items-center p-2 text-white rounded-lg hover:bg-[#474c59]"
              >
                <ExitToAppIcon style={{ fontSize: "18px" }} />
                <span className="ms-3 text-[13px] font-normal">Sign Out</span>
              </a>
            </li>
          </ul>
        </div>
      </aside>

      {/* Content */}
      <div className="p-4 sm:p-8 lg:ml-64 min-h-screen bg-gray-100">
        {renderActivePage()}
      </div>
    </>
  );
};

export default AdminPage;
