import React from "react";
import NavBar from "../components/Navbar";
import Footer from "../components/Footer";
import Banner from "../components/Banner";

const AboutUsPage = () => {
  return (
    <div className="w-full">
      <NavBar />

      <div className="">
        <Banner
          isMain={false}
          bigText1="About Us"
          bigText2=""
          miniText="Compare rates, make smart decisions"
        />
        {/* Section 1 */}
        <section className="w-full flex justify-center mb-8">
          <div className="w-full max-w-screen-limit h-full mx-auto px-5 sm:px-10">
            <div className="flex flex-col-reverse md:flex-row-reverse gap-3">
              {/* Text */}
              <div className="w-full md:w-1/2 p-5 text-[15px]">
                <p>
                  Welcome to <strong>etx.et</strong>, your go-to platform for
                  up-to-date exchange rates across different banks. Whether
                  you're a business professional, traveler, or simply someone
                  interested in currency trends, etx.et offers a comprehensive
                  view of the latest exchange rates for various currencies.
                </p>
                <br />
                <p>
                  We provide accurate, real-time data to help you make informed
                  financial decisions. In addition to exchange rates, we offer
                  in-depth data analysis and trends, enabling you to track
                  currency movements and understand the factors driving market
                  changes.
                </p>
                <br />
                <p>
                  At etx.et, we are committed to delivering a reliable and
                  user-friendly experience, making it easier for you to access
                  the financial information you need, whenever you need it. Stay
                  informed with etx.et, your trusted source for currency
                  exchange insights.
                </p>
              </div>

              {/* Image */}
              <div className="w-full md:w-1/2">
                <img src="./assets/aboutus.png" alt="about us pic" />
              </div>
            </div>
          </div>
        </section>

        {/* Section 2 */}
        {/* <section className="w-full flex justify-center bg-gray-50 py-10">
          <div className="w-full max-w-screen-limit h-full mx-auto p-5 sm:p-10">
            <div className="flex flex-col w-full gap-4">
              Title
              <div className="flex w-full justify-center items-center mb-8">
                <div className="flex flex-col justify-center items-center w-full">
                  <p className="font-bold text-2xl sm:text-4xl 2xl:text-5xl mb-2">
                    Why Choose Us
                  </p>
                  <p className="font-light text-sm sm:text-md">
                    We gather exchange rates from multiple banks, allowing you
                    to easily compare and choose the best options.
                  </p>
                </div>
              </div>

              Reasons
              <div className="flex flex-col md:flex-row justify-center items-center w-full gap-10 mb-8">
                <div className="flex flex-col w-full h-32 rounded-lg shadow-md bg-white p-5">
                  <p className="text-lg font-semibold">Informed Decision</p>
                  <p className="text-xs text-gray-500">
                    Make informed decisions with transparent rate comparisons at
                    your fingertips.
                  </p>
                </div>
                <div className="flex flex-col w-full h-32 rounded-lg shadow-md bg-white p-5">
                  <p className="text-lg font-semibold">Informed Decision</p>
                  <p className="text-xs text-gray-500">
                    Make informed decisions with transparent rate comparisons at
                    your fingertips.
                  </p>
                </div>
                <div className="flex flex-col w-full h-32 rounded-lg shadow-md bg-white p-5">
                  <p className="text-lg font-semibold">Informed Decision</p>
                  <p className="text-xs text-gray-500">
                    Make informed decisions with transparent rate comparisons at
                    your fingertips.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* Section 3 */}
        <section className="w-full flex justify-center py-10">
          <div className="w-full max-w-screen-limit h-full mx-auto p-5 sm:p-10">
            <div className="flex flex-col w-full">
              {/* Title */}
              <div className="flex w-full justify-center items-center mb-8">
                <div className="flex flex-col justify-center items-center w-full gap-5">
                  <p className="font-bold text-2xl sm:text-4xl 2xl:text-5xl">
                    That's all about us!
                  </p>
                  <p className="font-light text-sm sm:text-md text-center">
                    Have questions or need assistance? Reach out to us anytime,
                    and we’ll be happy to help. We're here to guide you with any
                    exchange rate inquiries.
                  </p>
                  {/* <button
                    type="button"
                    className="text-blue-500 hover:text-white border border-blue-500 hover:bg-blue-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
                  >
                    Get in Touch
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default AboutUsPage;
