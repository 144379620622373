import React, { useState } from "react";
import SmallTable from "./SmallTable";
import { banksExchangeRateHeader } from "../data/HeaderNames";

const AllBanksTable = ({ allBanksData }) => {
  const [activeTab, setActiveTab] = useState("Abay");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <section className="w-full flex justify-center mb-7">
      <div className="w-full max-w-screen-limit h-full mx-auto px-5 sm:px-5">
        {/* Title */}
        <div className="flex justify-between my-5">
          <div>
            <p className="font-semibold text-lg md:text-xl">All Bank Rates</p>
          </div>
          <div>
            <p className="text-xs"></p>
          </div>
        </div>

        {/* Tab */}
        <div className="mb-4 border-b border-gray-200">
          <ul
            className="flex overflow-x-scroll no-scrollbar -mb-px text-sm font-medium text-center"
            id="default-tab"
            role="tablist"
          >
            {allBanksData.map((bankInfo, index) => (
              <li key={index} className="me-2" role="presentation">
                <button
                  className={`inline-block p-4 border-b-2 min-w-[60px] rounded-t-lg ${
                    activeTab === bankInfo.bankShortName
                      ? "border-[#09143C] text-blue-500"
                      : "hover:text-gray-600 hover:border-gray-300"
                  }`}
                  id={`${bankInfo.bankShortName}-tab`}
                  type="button"
                  role="tab"
                  aria-controls={bankInfo.bankShortName}
                  aria-selected={activeTab === bankInfo.bankShortName}
                  onClick={() => handleTabClick(bankInfo.bankShortName)}
                >
                  <img
                    src={bankInfo.bankLogo}
                    alt={`${bankInfo.bankName}'s logo`}
                    className="w-7 h-7 text-[10px]"
                  />
                </button>
              </li>
            ))}
          </ul>
        </div>

        {/* Tab Content */}
        <div id="default-tab-content">
          {allBanksData.map(
            (bankInfo, index) =>
              activeTab === bankInfo.bankShortName && (
                <div
                  key={index}
                  className="p-4 rounded-lg bg-gray-50"
                  id={bankInfo.bankShortName}
                  role="tabpanel"
                  aria-labelledby={`${bankInfo.bankShortName}-tab`}
                >
                  <SmallTable
                    tableTitle={bankInfo.bankName}
                    lastUpdated={bankInfo.lastUpdated}
                    headerData={banksExchangeRateHeader}
                    bodyData={bankInfo.exchangeRates}
                  />
                </div>
              )
          )}
        </div>
      </div>
    </section>
  );
};

export default AllBanksTable;
