import React from "react";

const OverallExchangeSkeleton = () => {
  return (
    <section className="w-full flex justify-center mb-16">
      <div className="w-full flex flex-col justify-between gap-5 max-w-screen-limit h-full px-5">
        {/* Title */}
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mt-5">
          <div>
            <div className="h-7 w-60 bg-gray-200 rounded-full animate-pulse"></div>
          </div>
          {/* Dropdown */}
          <div className="flex items-center justify-start gap-4">
            <label className="block">
              <div className="h-4 w-24 mb-1 bg-gray-200 rounded-full animate-pulse"></div>
              <div className="h-10 w-28 bg-gray-200 rounded-md animate-pulse"></div>
            </label>
            <label className="block">
              <div className="h-4 w-24 mb-1 bg-gray-200 rounded-full animate-pulse"></div>
              <div className="h-10 w-28 bg-gray-200 rounded-md animate-pulse"></div>
            </label>
          </div>
        </div>

        {/* Chart */}
        <div className="h-[300px] w-full bg-gray-200 rounded-md animate-pulse"></div>
      </div>
    </section>
  );
};

export default OverallExchangeSkeleton;
