import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PaidIcon from "@mui/icons-material/Paid";
import BadgeIcon from "@mui/icons-material/Badge";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { EtxContext } from "../../context/etxContext";

const DashboardPage = () => {
  const { setActivePage } = useContext(EtxContext);

  //! Constants
  const API_KEY = process.env.REACT_APP_API_KEY;

  //! Use states
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState({});

  //! Use effects
  useEffect(() => {
    //// eslint-disable-next-line
    async function fetchData() {
      try {
        setLoading(true);
        const response = await axios.get(API_KEY);
        setData(response.data);
        setError(null);
      } catch (err) {
        setError(err.message);
        setData(null);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [API_KEY]);

  //! Functions
  function countCurrenciesAndBanks(data) {
    const currencySet = new Set();
    const bankSet = new Set();

    Object.keys(data).forEach((bankName) => {
      const bankData = data[bankName];

      if (Array.isArray(bankData) && bankData.length > 0) {
        bankSet.add(bankName);

        bankData.forEach((entry) => {
          if (entry["Currency Code"]) {
            currencySet.add(entry["Currency Code"]);
          }
        });
      }
    });

    const numberOfCurrencies = currencySet.size;
    const numberOfBanks = bankSet.size;

    return { numberOfCurrencies, numberOfBanks };
  }

  const dashboardData = countCurrenciesAndBanks(data);

  function getTop5LatestRates(data) {
    const exchangeRates = [];

    Object.keys(data).forEach((bankName) => {
      const bankData = data[bankName];

      if (Array.isArray(bankData) && bankData.length > 0) {
        bankData.forEach((entry) => {
          if (entry["Currency Code"] && entry["Created At"]) {
            exchangeRates.push({
              currencyName: entry["Currency Name"],
              currencyCode: entry["Currency Code"],
              buyingPrice: parseFloat(entry["Cash Buying"]),
              sellingPrice: parseFloat(entry["Cash Selling"]),
              bankName: bankName,
              createdAt: new Date(entry["Created At"]),
            });
          }
        });
      }
    });

    exchangeRates.sort((a, b) => b.createdAt - a.createdAt);

    return exchangeRates.slice(0, 5);
  }

  const topRates = getTop5LatestRates(data);

  return (
    <div className="p-5 sm:p-7 bg-white  rounded-lg shadow-sm h-full">
      {/* Page title */}
      <p className="text-xl font-semibold">Dashboard Page</p>

      {/* Breadcrumbs */}
      <nav className="flex mt-2" aria-label="Breadcrumb">
        <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
          <li className="inline-flex items-center">
            <Link
              to="/"
              className="inline-flex items-center text-xs font-normal text-gray-700 hover:text-blue-600"
            >
              <svg
                className="w-3 h-3 me-2.5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
              </svg>
              Home
            </Link>
          </li>

          <li aria-current="page">
            <div className="flex items-center">
              <svg
                className="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
              <span className="ms-1 text-xs font-normal text-gray-500 md:ms-2 dark:text-gray-400">
                Dashboard
              </span>
            </div>
          </li>
        </ol>
      </nav>

      <div className="h-[calc(100%-4rem)] mt-2">
        {/* Dashboard cards */}
        <section className="flex flex-col md:flex-row gap-7 mt-7">
          {/* Banks card */}
          <div className="flex-grow h-28 border-[1px] border-gray-50 p-4 rounded-lg shadow-md">
            <div className="flex items-center h-full">
              {/* data text */}
              <div className="flex w-3/4 h-full gap-4">
                <div className="flex h-full w-2 rounded bg-purple-600"></div>
                <div className="flex flex-col justify-around h-full w-full flex-grow">
                  <p className="text-gray-400 text-sm">Banks</p>
                  {loading && !error && (
                    <div className="animate-pulse h-10 w-10 bg-gray-200 rounded-lg"></div>
                  )}
                  {!loading && !error && (
                    <p className="text-black font-semibold text-3xl">
                      {dashboardData.numberOfBanks}
                    </p>
                  )}
                </div>
              </div>
              {/* icon */}
              <div className="flex justify-center items-center w-1/4 h-full">
                <AccountBalanceIcon
                  className="bg-gradient-to-b from-violet-200 to-purple-500 rounded-lg min-w-[60px] max-w-[60px] min-h-[60px] max-h-[60px]"
                  style={{ width: "100%", height: "100%", padding: "10px" }}
                />
              </div>
            </div>
          </div>

          {/* Currencies card */}
          <div className="flex-grow h-28 border-[1px] border-gray-50 p-4 rounded-lg shadow-md">
            <div className="flex items-center h-full">
              {/* data text */}
              <div className="flex w-3/4 h-full gap-4">
                <div className="flex h-full w-2 rounded bg-green-400"></div>
                <div className="flex flex-col justify-around h-full w-full flex-grow">
                  <p className="text-gray-400 text-sm">Currencies</p>
                  {loading && !error && (
                    <div className="animate-pulse h-10 w-10 bg-gray-200 rounded-lg"></div>
                  )}
                  {!loading && !error && (
                    <p className="text-black font-semibold text-3xl">
                      {dashboardData.numberOfCurrencies}
                    </p>
                  )}
                </div>
              </div>
              {/* icon */}
              <div className="flex justify-center items-center w-1/4 h-full">
                <PaidIcon
                  className="bg-gradient-to-b from-green-100 to-green-400 rounded-lg text-green-800 min-w-[60px] max-w-[60px] min-h-[60px] max-h-[60px]"
                  style={{ width: "100%", height: "100%", padding: "10px" }}
                />
              </div>
            </div>
          </div>

          {/* Admin card */}
          <div className="flex-grow h-28 border-[1px] border-gray-50 p-4 rounded-lg shadow-md">
            <div className="flex items-center h-full">
              {/* data text */}
              <div className="flex w-3/4 h-full gap-4">
                <div className="flex h-full w-2 rounded bg-yellow-200"></div>
                <div className="flex flex-col justify-around h-full w-full flex-grow">
                  <p className="text-gray-400 text-sm">Admins</p>
                  {loading && !error && (
                    <div className="animate-pulse h-10 w-10 bg-gray-200 rounded-lg"></div>
                  )}
                  {!loading && !error && (
                    <p className="text-black font-semibold text-3xl">4</p>
                  )}
                </div>
              </div>
              {/* icon */}
              <div className="flex justify-center items-center w-1/4 h-full">
                <BadgeIcon
                  className="bg-gradient-to-b from-yellow-50 to-yellow-200 rounded-lg text-yellow-500 min-w-[60px] max-w-[60px] min-h-[60px] max-h-[60px]"
                  style={{ width: "100%", height: "100%", padding: "10px" }}
                />
              </div>
            </div>
          </div>
        </section>

        {/* Other data */}
        <section className="mt-7 h-[calc(100%-15rem)]">
          <div className="flex flex-col-reverse md:flex-row gap-7">
            {/* Latest exchange rate table */}
            <div className="flex flex-col gap-3 w-full md:w-3/5 p-4 border-[1px] border-gray-50 rounded-lg shadow-md">
              <p className="font-semibold text-[16px]">Latest Exchange Rates</p>

              {/* Table */}
              <div className="relative overflow-x-auto rounded-lg">
                <table className="w-full text-sm text-left text-gray-600">
                  <thead>
                    <tr className="bg-gray-100 border-b">
                      <th className="px-3 py-4 font-medium text-gray-500 text-[13px]">
                        Currency Code
                      </th>
                      <th className="px-3 py-4 font-medium text-gray-500 text-[13px]">
                        Buying
                      </th>
                      <th className="px-3 py-4 font-medium text-gray-500 text-[13px]">
                        Selling
                      </th>
                      <th className="px-3 py-4 font-medium text-gray-500 text-[13px]">
                        Bank Name
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {topRates.map((rate, index) => (
                      <tr key={index} className="bg-white border-b font-medium">
                        <td className="px-5 py-3">
                          <div className="w-full flex justify-center items-center gap-2">
                            <img
                              src={`./assets/currencies/${rate.currencyCode.toLowerCase()}.png`}
                              alt={rate.currencyCode}
                              className="w-[20px]"
                            />
                            {rate.currencyCode}
                          </div>
                        </td>
                        <td className="px-5 py-3 text-green-500">
                          {rate.buyingPrice.toFixed(2)}
                        </td>
                        <td className="px-5 py-3 text-red-500">
                          {rate.sellingPrice.toFixed(2)}
                        </td>
                        <td className="px-5 py-3">
                          <div className="w-full flex justify-center items-center gap-2">
                            <img
                              src={`./assets/banks/${rate.bankName.toLowerCase()}.png`}
                              alt={rate.bankName}
                              className="w-[20px]"
                            />
                            {rate.bankName}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <button
                className="flex justify-center items-center font-semibold text-sm my-2"
                onClick={() => setActivePage("currency")}
              >
                See more
                <ArrowRightAltIcon />
              </button>
            </div>

            {/* Black card */}
            <div className="flex w-full md:w-2/5 text-white border-[1px] border-gray-50 rounded-lg shadow-md">
              <div className="relative w-full h-full flex flex-col justify-center items-center bg-[#191f2f] text-white shadow-sm border border-slate-200 rounded-lg p-2">
                <div className="p-3 text-center">
                  <div className="flex justify-center mb-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-10 h-10 text-purple-400"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.362 5.214A8.252 8.252 0 0 1 12 21 8.25 8.25 0 0 1 6.038 7.047 8.287 8.287 0 0 0 9 9.601a8.983 8.983 0 0 1 3.361-6.867 8.21 8.21 0 0 0 3 2.48Z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 18a3.75 3.75 0 0 0 .495-7.468 5.99 5.99 0 0 0-1.925 3.547 5.975 5.975 0 0 1-2.133-1.001A3.75 3.75 0 0 0 12 18Z"
                      />
                    </svg>
                  </div>
                  <div className="flex justify-center mb-2">
                    <h5 className="text-2xl font-semibold">
                      System Reliability
                    </h5>
                  </div>
                  <p className="block text-slate-400 leading-normal font-light mb-4 max-w-lg">
                    Monitor the reliability and stability of our systems.
                  </p>
                  <div className="text-center">
                    <button
                      className="min-w-32 rounded-md bg-slate-800 py-2 px-4 border border-transparent text-center text-sm text-white transition-all shadow-md hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 hover:bg-slate-700 active:shadow-none"
                      type="button"
                    >
                      View More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default DashboardPage;
