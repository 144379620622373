import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import NavBar from "../components/Navbar";
import Footer from "../components/Footer";
import Banner from "../components/Banner";
import { useNavigate } from "react-router-dom";

const NewsPage = () => {
  const navigate = useNavigate();
  // const lastVisibleNewsRef = useRef(null); // Track the last visible news item before loading more
  const lastNewsIdRef = useRef(null); // Track last news ID for pagination

  //! State
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [lastNewsId, setLastNewsId] = useState(null);
  const [hasMore, setHasMore] = useState(true);

  //! Effects
  const fetchNews = async (beforeId = null) => {
    try {
      setLoading(true);
      const url = beforeId
        ? `https://api.etx.et/news?before=${beforeId}`
        : "https://api.etx.et/news";
      const response = await axios.get(url);

      const dataArray = Array.isArray(response.data)
        ? response.data
        : response.data.data;

      if (!Array.isArray(dataArray)) {
        throw new Error("Unexpected API response format.");
      }

      // Filter out articles with empty text or no images
      const filteredData = dataArray.filter(
        (article) =>
          article.text.trim() !== "" &&
          article.images &&
          article.images.length > 0
      );

      // Sort the filtered news by date descending
      const sortedNews = filteredData.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );

      if (sortedNews.length > 0) {
        // Update the last news ID for pagination
        setLastNewsId(sortedNews[sortedNews.length - 1].id);

        if (beforeId) {
          // Append new news to existing news
          setNews((prevNews) => [...prevNews, ...sortedNews]);
        } else {
          // Initial fetch, replace existing news
          setNews(sortedNews);
        }
      }

      // If fewer news items are returned, there are no more to load
      if (sortedNews.length === 0 || sortedNews.length < 10) {
        setHasMore(false);
      }

      setError(null);
    } catch (err) {
      console.error("Error fetching news:", err);
      setError(err.message || "An error occurred while fetching news.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNews();
    // eslint-disable-next-line
  }, []);

  //! Load more handler
  const loadMoreNews = () => {
    if (hasMore && !loading) {
      lastNewsIdRef.current = lastNewsId;
      fetchNews(lastNewsId);
    }
  };

  // useEffect(() => {
  //   if (news.length > 0) {
  //     const lastVisibleIndex = news.findIndex(
  //       (article) => article.id === lastNewsIdRef.current
  //     );
  //     if (lastVisibleIndex !== -1) {
  //       const lastVisibleArticle = document.getElementById(
  //         `news-${lastVisibleIndex}`
  //       );
  //       if (lastVisibleArticle) {
  //         lastVisibleArticle.scrollIntoView({ behavior: "smooth" });
  //       }
  //     }
  //   }
  // }, [news]);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (news.length > 0) {
        const lastVisibleIndex = news.findIndex(
          (article) => article.id === lastNewsIdRef.current
        );
        if (lastVisibleIndex !== -1) {
          const lastVisibleArticle = document.getElementById(
            `news-${lastVisibleIndex}`
          );
          if (lastVisibleArticle) {
            lastVisibleArticle.scrollIntoView({ behavior: "smooth" });
          }
        }
      }
    }, 100);

    return () => clearTimeout(timer);
  }, [news]);

  return (
    <div className="w-full">
      <NavBar />

      <div className="">
        <Banner
          isMain={false}
          bigText1="News"
          bigText2=""
          miniText="Get up-to-date daily updates on rates and financial markets"
        />

        {loading && !error && <p>Loading...</p>}

        {!loading && error && <p>{error}</p>}

        {!loading && !error && (
          <>
            {/* News Container */}
            <section className="w-full flex justify-center pb-8">
              <div className="w-full max-w-screen-limit h-full mx-auto p-5 sm:p-10">
                <div className="flex flex-col">
                  {/* Title */}
                  <div className="">
                    <p className="font-semibold text-lg md:text-xl">News</p>
                  </div>

                  {/* Container */}
                  <div className="flex flex-wrap justify-start sm:justify-start gap-6 mt-5">
                    {/* Card */}
                    {news.map((article, index) => (
                      <div
                        key={article.id}
                        id={`news-${index}`} // Assigning unique IDs to each news item
                        className="w-full sm:max-w-[calc(50%-13px)] lg:max-w-[calc(33%-13px)] bg-white border border-gray-200 rounded-lg shadow"
                      >
                        <img
                          className="rounded-t-lg w-full h-[247px] object-cover"
                          src={article.images[0]}
                          alt=""
                        />
                        <div className="p-5">
                          <p className="mb-3 font-normal text-gray-700 line-clamp-3">
                            {article.text}
                          </p>
                          <button
                            className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            onClick={() =>
                              navigate("/newsDetail", { state: { article } })
                            }
                          >
                            Read more
                            <svg
                              className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 14 10"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M1 5h12m0 0L9 1m4 4L9 9"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>

                  {/* Load more btn */}
                  {hasMore && (
                    <div className="w-full flex justify-center items-center mt-5">
                      <button
                        className="px-4 py-2 bg-blue-500 text-white rounded"
                        onClick={loadMoreNews}
                        disabled={loading}
                      >
                        {loading ? "Loading..." : "Load next"}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </section>
          </>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default NewsPage;
