import React from "react";
import SmallTableSkeleton from "./SmallTableSkeleton";

const AllBanksTableSkeleton = () => {
  return (
    <section className="w-full flex justify-center mb-7">
      <div className="w-full max-w-screen-limit h-full mx-auto px-5 sm:px-5">
        {/* Tab */}
        <div className="mb-4 border-b border-gray-200">
          <ul
            className="flex flex-wrap -mb-px text-sm font-medium text-center"
            id="default-tab"
            role="tablist"
          >
            <li className="me-2" role="presentation">
              <div className="h-12 w-12 bg-gray-200 rounded-md animate-pulse"></div>
            </li>
            <li className="me-2" role="presentation">
              <div className="h-12 w-12 bg-gray-200 rounded-md animate-pulse"></div>
            </li>
            <li className="me-2" role="presentation">
              <div className="h-12 w-12 bg-gray-200 rounded-md animate-pulse"></div>
            </li>
          </ul>
        </div>

        {/* Tab Content */}
        <div id="default-tab-content">
          <div className="p-4 rounded-lg bg-gray-50 dark:bg-gray-800">
            <SmallTableSkeleton />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AllBanksTableSkeleton;
