import React from "react";
import TableRows from "./TableRows";

const Table = ({ title1, title2, headerData, bodyData }) => {
  return (
    <section className="w-full flex justify-center mb-7">
      <div className="w-full max-w-screen-limit h-full mx-auto px-5 sm:px-5">
        {/* Title */}
        <div className="flex justify-between my-5">
          <div>
            <p className="font-semibold text-lg md:text-xl">{title1}</p>
          </div>
          <div>
            <p className="text-xs">{title2}</p>
          </div>
        </div>

        {/* Table */}
        <div className="relative overflow-x-auto border rounded-lg">
          <table className="mx-auto text-sm text-left w-full">
            {/* Table Header */}
            <thead className="text-sm font-normal bg-[#09143C] text-white border-b">
              <tr>
                {headerData.map((header, index) => (
                  <td key={index} className="px-5 py-3 text-center">
                    {header}
                  </td>
                ))}
              </tr>
            </thead>

            {/* Table body */}
            <tbody>
              {bodyData.map((data, rowIndex) => (
                <tr
                  key={rowIndex}
                  className="border-b hover:bg-gray-50 font-medium"
                >
                  {Object.entries(data).map(([key, value], colIndex) => (
                    <TableRows key={colIndex} colName={key} value={value} />
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default Table;
