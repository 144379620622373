import React from "react";
import Table from "./Table";
import { lowSellingRates, topBuyingRates } from "../data/HeaderNames";

const HighBuyingAndLowSellingTable = ({
  data,
  getTopBuying,
  getLowestSelling,
}) => {
  return (
    <section className="w-full flex justify-center mb-5">
      <div className="w-full flex flex-col justify-between gap-5 max-w-screen-limit h-full">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center">
          <Table
            title1="Highest Buying Rates"
            headerData={topBuyingRates}
            bodyData={getTopBuying(data)}
          />
          <Table
            title1="Lowest Selling Rates"
            headerData={lowSellingRates}
            bodyData={getLowestSelling(data)}
          />
        </div>
      </div>
    </section>
  );
};

export default HighBuyingAndLowSellingTable;
