import React from "react";

const Banner = ({ isMain, bigText1, bigText2, miniText }) => {
  return (
    <section
      className={`w-full bg-blue-100 ${
        isMain ? "h-[250px] sm:h-[300px]" : "h-[150px] sm:h-[230px]"
      } flex justify-center mb-8`}
    >
      <div
        className={`flex flex-col justify-center ${
          isMain ? "" : "items-center"
        } w-full max-w-screen-limit h-full px-10`}
      >
        <p className="font-bold text-2xl sm:text-4xl mb-2 2xl:leading-tight">
          {bigText1}
          <br className="hidden md:block" /> {bigText2}
        </p>
        <p className="font-light text-sm sm:text-md">{miniText}</p>
      </div>
    </section>
  );
};

export default Banner;
