import { Area, AreaChart, ResponsiveContainer } from "keep-react";

export const Chart = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart data={data}>
        <defs>
          <linearGradient id="price" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#1B4DFF" stopOpacity={0.3} />
            <stop offset="90%" stopColor="#1B4DFF" stopOpacity={0} />
          </linearGradient>
        </defs>
        <Area
          type="natural"
          dataKey="price"
          stroke="#1B4DFF"
          strokeWidth={2.5}
          fillOpacity={1}
          fill="url(#price)"
        />

        {/* <ChartTooltip content={<CustomTooltip />} /> */}
      </AreaChart>
    </ResponsiveContainer>
  );
};
