import React from "react";

const TableSkeleton = () => {
  return (
    <section className="w-full flex justify-center mb-7">
      <div className="w-full max-w-screen-limit h-full mx-auto px-5 sm:px-5">
        {/* Title */}
        <div className="flex justify-between my-5">
          <div>
            <div className="h-7 w-60 bg-gray-200 rounded-full animate-pulse"></div>
          </div>
        </div>

        {/* Table */}
        <div className="relative overflow-x-auto border">
          <table className="mx-auto text-sm text-left w-full">
            {/* Table Header */}
            <thead className="text-sm font-normal bg-[#09143C] text-white border-b">
              <tr>
                <td className="ps-7 py-3">Code</td>
                <td className="ps-7 py-3">Buying</td>
                <td className="ps-7 py-3">Selling</td>
                <td className="ps-7 py-3">Bank</td>
              </tr>
            </thead>

            {/* Table body */}
            <tbody>
              <tr className="border-b hover:bg-gray-50 font-medium">
                <td className="px-6 py-2 text-center">
                  <p>
                    <div className="h-4 w-24 bg-gray-200 rounded-full animate-pulse"></div>
                  </p>
                </td>
                <td className="px-6 py-2 ">
                  <div className="h-4 w-24 bg-gray-200 rounded-full animate-pulse"></div>
                </td>
                <td className="px-6 py-2 ">
                  <div className="h-4 w-24 bg-gray-200 rounded-full animate-pulse"></div>
                </td>
                <td className="px-6 py-2 ">
                  <div className="h-4 w-24 bg-gray-200 rounded-full animate-pulse"></div>
                </td>
              </tr>

              <tr className="border-b hover:bg-gray-50 font-medium">
                <td className="px-6 py-2 text-center">
                  <p>
                    <div className="h-4 w-24 bg-gray-200 rounded-full animate-pulse"></div>
                  </p>
                </td>
                <td className="px-6 py-2 ">
                  <div className="h-4 w-24 bg-gray-200 rounded-full animate-pulse"></div>
                </td>
                <td className="px-6 py-2 ">
                  <div className="h-4 w-24 bg-gray-200 rounded-full animate-pulse"></div>
                </td>
                <td className="px-6 py-2 ">
                  <div className="h-4 w-24 bg-gray-200 rounded-full animate-pulse"></div>
                </td>
              </tr>

              <tr className="border-b hover:bg-gray-50 font-medium">
                <td className="px-6 py-2 text-center">
                  <p>
                    <div className="h-4 w-24 bg-gray-200 rounded-full animate-pulse"></div>
                  </p>
                </td>
                <td className="px-6 py-2 ">
                  <div className="h-4 w-24 bg-gray-200 rounded-full animate-pulse"></div>
                </td>
                <td className="px-6 py-2 ">
                  <div className="h-4 w-24 bg-gray-200 rounded-full animate-pulse"></div>
                </td>
                <td className="px-6 py-2 ">
                  <div className="h-4 w-24 bg-gray-200 rounded-full animate-pulse"></div>
                </td>
              </tr>

              <tr className="border-b hover:bg-gray-50 font-medium">
                <td className="px-6 py-2 text-center">
                  <p>
                    <div className="h-4 w-24 bg-gray-200 rounded-full animate-pulse"></div>
                  </p>
                </td>
                <td className="px-6 py-2 ">
                  <div className="h-4 w-24 bg-gray-200 rounded-full animate-pulse"></div>
                </td>
                <td className="px-6 py-2 ">
                  <div className="h-4 w-24 bg-gray-200 rounded-full animate-pulse"></div>
                </td>
                <td className="px-6 py-2 ">
                  <div className="h-4 w-24 bg-gray-200 rounded-full animate-pulse"></div>
                </td>
              </tr>

              <tr className="border-b hover:bg-gray-50 font-medium">
                <td className="px-6 py-2 text-center">
                  <p>
                    <div className="h-4 w-24 bg-gray-200 rounded-full animate-pulse"></div>
                  </p>
                </td>
                <td className="px-6 py-2 ">
                  <div className="h-4 w-24 bg-gray-200 rounded-full animate-pulse"></div>
                </td>
                <td className="px-6 py-2 ">
                  <div className="h-4 w-24 bg-gray-200 rounded-full animate-pulse"></div>
                </td>
                <td className="px-6 py-2 ">
                  <div className="h-4 w-24 bg-gray-200 rounded-full animate-pulse"></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default TableSkeleton;
