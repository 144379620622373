import axios from "axios";
import React, { useEffect, useState } from "react";
import { ChartTwo } from "./AreaChart2";
import { banksInfo } from "../data/BankInformations";
import { currencyInfo } from "../data/CurrencyInfo";

const OverallExchangeGraph = ({ title1, title2 }) => {
  //! States
  const [selectedBank, setSelectedBank] = useState(Object.keys(banksInfo)[0]);
  const [selectedCurrency, setSelectedCurrency] = useState(
    currencyInfo[0].currencyCode
  );
  const [selectedDateRange, setSelectedDateRange] = useState("Last 7 days");
  const [data, setData] = useState([]);

  //! Use Effect
  useEffect(() => {
    const getDateType = (range) => {
      switch (range) {
        case "Last 7 days":
          return "weekly";
        case "This month":
          return "monthly";
        default:
          return "yearly";
      }
    };

    async function fetchFilteredData() {
      try {
        const response = await axios.get(
          `https://api.etx.et/getAllRatesByCode`,
          {
            params: {
              currencyCode: selectedCurrency,
              bankName: selectedBank,
              dateType: getDateType(selectedDateRange),
            },
          }
        );

        setData(response.data[selectedBank][selectedCurrency] || []);
      } catch (err) {
        console.error("Error fetching filtered data:", err);
        setData([]);
      }
    }

    fetchFilteredData();
  }, [selectedBank, selectedCurrency, selectedDateRange]);

  //! Functions
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return "Invalid Date";

    const month = date.toLocaleString("en-US", { month: "short" });
    const day = date.toLocaleString("en-US", { day: "2-digit" });

    return `${month} - ${day}`;
  };

  //! Constants
  const availableBanks = Object.keys(banksInfo).sort();
  const availableCurrencies = currencyInfo
    .map(({ currencyCode }) => currencyCode)
    .sort();

  const formattedData = data
    .map((item) => ({
      ...item,
      createdDate: formatDate(item.Date),
    }))
    .sort((a, b) => new Date(a.createdDate) - new Date(b.createdDate));

  return (
    <section className="w-full flex justify-center mb-16 mt-8">
      <div className="w-full flex flex-col justify-between gap-5 max-w-screen-limit h-full px-5">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
          <div>
            <p className="font-semibold text-xl">{title1}</p>
          </div>
          <div className="flex items-center justify-start gap-4">
            {/* Bank Dropdown */}
            <label className="block">
              <span className="text-gray-700">Bank</span>
              <select
                className="form-select mt-1 block w-full rounded-md border-gray-400"
                value={selectedBank}
                onChange={(e) => {
                  const newBank = e.target.value;
                  setSelectedBank(newBank);
                  setSelectedCurrency(availableCurrencies[0]);
                }}
              >
                {availableBanks.map((bankKey) => (
                  <option key={bankKey} value={bankKey}>
                    {banksInfo[bankKey]?.bankShortName || bankKey}
                  </option>
                ))}
              </select>
            </label>

            {/* Currency Dropdown */}
            <label className="block">
              <span className="text-gray-700">Currency</span>
              <select
                className="form-select mt-1 block w-full rounded-md border-gray-400"
                value={selectedCurrency}
                onChange={(e) => setSelectedCurrency(e.target.value)}
              >
                {availableCurrencies.map((currencyKey) => (
                  <option key={currencyKey} value={currencyKey}>
                    {currencyKey}
                  </option>
                ))}
              </select>
            </label>

            {/* Date Range Dropdown */}
            <label className="block">
              <span className="text-gray-700">Date Range</span>
              <select
                className="form-select mt-1 block w-full rounded-md border-gray-400"
                value={selectedDateRange}
                onChange={(e) => setSelectedDateRange(e.target.value)}
              >
                <option value="Last 7 days">Last 7 days</option>
                <option value="This month">This month</option>
                <option value="This year">This year</option>
              </select>
            </label>
          </div>
        </div>

        {/* Render Chart */}
        {formattedData.length > 0 ? (
          <ChartTwo data={formattedData} />
        ) : (
          <div className="text-center text-gray-500 mt-16">
            No data available for the selected options.
          </div>
        )}
      </div>
    </section>
  );
};

export default OverallExchangeGraph;
