import React from "react";
import { Chart } from "./AreaChart";

const Card = ({ cardData, purpose }) => {
  return (
    <div className="my-5 w-[300px] sm:w-[330px] h-[150px] rounded-xl flex items-center justify-between border shadow-lg">
      {/* Data */}
      <div className="flex flex-col justify-center w-full h-full px-4 py-3">
        <div className="flex items-center gap-2 xl:gap-3">
          <img
            src={cardData.imageUrl}
            alt={cardData.currencyCode.toLowerCase()}
            className="h-10"
          />

          <div className="flex flex-col justify-center w-full">
            <p className="text-md font-semibold">{cardData.currencyName}</p>
            <p className="text-sm font-normal ">{cardData.currencyCode}</p>
          </div>
        </div>

        {purpose === "buying" ? (
          <p className="text-2xl font-bold mt-1">{cardData.buyingPrice}</p>
        ) : (
          <p className="text-2xl font-bold mt-1">{cardData.sellingPrice}</p>
        )}
        <div className="flex items-center gap-2">
          <img
            src={`./assets/banks/${cardData.bankName.toLowerCase()}.png`}
            alt=""
            className="w-[18px]"
          />
          <p className="text-sm text-gray-500">{cardData.bankName}</p>
        </div>
      </div>
      {/* Graph */}
      <div className="w-full h-full px-3">
        <Chart data={cardData.data} />
      </div>
    </div>
  );
};

export default Card;
