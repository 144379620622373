import React from "react";

const SmallTableSkeleton = () => {
  return (
    <section className="w-full">
      {/* Table */}
      <div className=" overflow-x-auto">
        <div className="h-5 w-52 bg-gray-200 rounded-full animate-pulse"></div>{" "}
        <div className="h-4 w-40 my-2 bg-gray-200 rounded-full animate-pulse"></div>{" "}
        <table className="mx-auto text-sm w-full table-fixed">
          {/* Table Header */}
          <thead>
            <tr className="font-medium text-gray-700 border-b bg-gray-200">
              <td className="p-7 py-2">Code</td>
              <td className="p-7 py-2">Buying</td>
              <td className="p-7 py-2">Selling</td>
            </tr>
          </thead>

          {/* Table body */}
          <tbody>
            <tr className="border-b hover:bg-gray-50 font-medium">
              <td className="px-6 py-2 ">
                <div className="h-4 w-24 bg-gray-200 rounded-full animate-pulse"></div>
              </td>
              <td className="px-6 py-2 ">
                <div className="h-4 w-24 bg-gray-200 rounded-full animate-pulse"></div>
              </td>
              <td className="px-6 py-2 ">
                <div className="h-4 w-24 bg-gray-200 rounded-full animate-pulse"></div>
              </td>
            </tr>

            <tr className="border-b hover:bg-gray-50 font-medium">
              <td className="px-6 py-2 text-center">
                <p>
                  <div className="h-4 w-24 bg-gray-200 rounded-full animate-pulse"></div>
                </p>
              </td>
              <td className="px-6 py-2 ">
                <div className="h-4 w-24 bg-gray-200 rounded-full animate-pulse"></div>
              </td>
              <td className="px-6 py-2 ">
                <div className="h-4 w-24 bg-gray-200 rounded-full animate-pulse"></div>
              </td>
            </tr>

            <tr className="border-b hover:bg-gray-50 font-medium">
              <td className="px-6 py-2 text-center">
                <p>
                  <div className="h-4 w-24 bg-gray-200 rounded-full animate-pulse"></div>
                </p>
              </td>
              <td className="px-6 py-2 ">
                <div className="h-4 w-24 bg-gray-200 rounded-full animate-pulse"></div>
              </td>
              <td className="px-6 py-2 ">
                <div className="h-4 w-24 bg-gray-200 rounded-full animate-pulse"></div>
              </td>
            </tr>

            <tr className="border-b hover:bg-gray-50 font-medium">
              <td className="px-6 py-2 ">
                <div className="h-4 w-24 bg-gray-200 rounded-full animate-pulse"></div>
              </td>
              <td className="px-6 py-2 ">
                <div className="h-4 w-24 bg-gray-200 rounded-full animate-pulse"></div>
              </td>
              <td className="px-6 py-2 ">
                <div className="h-4 w-24 bg-gray-200 rounded-full animate-pulse"></div>
              </td>
            </tr>

            <tr className="border-b hover:bg-gray-50 font-medium">
              <td className="px-6 py-2 ">
                <div className="h-4 w-24 bg-gray-200 rounded-full animate-pulse"></div>
              </td>
              <td className="px-6 py-2 ">
                <div className="h-4 w-24 bg-gray-200 rounded-full animate-pulse"></div>
              </td>
              <td className="px-6 py-2 ">
                <div className="h-4 w-24 bg-gray-200 rounded-full animate-pulse"></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default SmallTableSkeleton;
