export const currencyInfo = [
  { currencyName: "United Arab Emirates Dirham", currencyCode: "AED" },
  { currencyName: "Australian Dollar", currencyCode: "AUD" },
  { currencyName: "Canadian Dollar", currencyCode: "CAD" },
  { currencyName: "Swiss Franc", currencyCode: "CHF" },
  { currencyName: "Chinese Yuan", currencyCode: "CNY" },
  { currencyName: "Djiboutian Franc", currencyCode: "DJF" },
  { currencyName: "Danish Krone", currencyCode: "DKK" },
  { currencyName: "Euro", currencyCode: "EUR" },
  { currencyName: "British Pound Sterling", currencyCode: "GBP" },
  { currencyName: "Indian Rupee", currencyCode: "INR" },
  { currencyName: "Japanese Yen", currencyCode: "JPY" },
  { currencyName: "Kenyan Shilling", currencyCode: "KES" },
  { currencyName: "Kuwaiti Dinar", currencyCode: "KWD" },
  { currencyName: "Norwegian Krone", currencyCode: "NOK" },
  { currencyName: "Saudi Riyal", currencyCode: "SAR" },
  { currencyName: "Swedish Krona", currencyCode: "SEK" },
  { currencyName: "Turkish Lira", currencyCode: "TRY" },
  { currencyName: "United States Dollar", currencyCode: "USD" },
  { currencyName: "South African Rand", currencyCode: "ZAR" },
];
