import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
// import NotificationsIcon from "@mui/icons-material/Notifications";

const NavBar = () => {
  //! Consts
  const today = new Date();

  //! States
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location.pathname);

  //! Functions
  const formattedDate = today.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const formattedTime = today.toLocaleTimeString("en-US", {
    weekday: "short",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  const handleTabClick = (path) => {
    setActiveTab(path);
  };

  return (
    <nav className="p-0 bg-base-100 w-full flex justify-center shadow-md">
      <div className="navbar w-full max-w-screen-limit h-full px-5">
        {/* Dropdown + Logo */}
        <div className="navbar-start w-16">
          {/* dropdown */}
          <div className="dropdown">
            <div tabIndex={0} role="button" className="btn btn-ghost md:hidden">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h8m-8 6h16"
                />
              </svg>
            </div>
            <ul
              tabIndex={0}
              className="menu menu-sm dropdown-content bg-base-100 rounded-lg mt-3 w-52 p-2 shadow-lg py-5 z-50"
            >
              <li>
                <Link
                  to="/"
                  onClick={() => handleTabClick("/")}
                  className={`${
                    activeTab === "/" ? "font-bold text-[15px] bg-gray-200" : ""
                  } py-2`}
                >
                  Exchange
                </Link>
              </li>
              <li>
                <Link
                  to="/insights"
                  onClick={() => handleTabClick("/insights")}
                  className={`${
                    activeTab === "/insights"
                      ? "font-bold text-[15px] bg-gray-200"
                      : ""
                  } py-2`}
                >
                  Insights
                </Link>
              </li>
              <li>
                <Link
                  to="/news"
                  onClick={() => handleTabClick("/news")}
                  className={`${
                    activeTab === "/news"
                      ? "font-bold text-[15px] bg-gray-200"
                      : ""
                  } py-2`}
                >
                  News
                </Link>
              </li>
              <li>
                <Link
                  to="/about"
                  onClick={() => handleTabClick("/about")}
                  className={`${
                    activeTab === "/about"
                      ? "font-bold text-[15px] bg-gray-200"
                      : ""
                  } py-2`}
                >
                  About Us
                </Link>
              </li>
            </ul>
          </div>

          {/* <img src="./assets/etxLogo.png" alt="etx logo" /> */}
          <a href="/" className="btn btn-ghost text-xl">
            ETX
          </a>
        </div>

        {/* Links */}
        <div className="navbar-start hidden md:flex md:gap-1">
          <ul className="menu menu-horizontal px-1">
            <li>
              <Link
                to="/"
                onClick={() => handleTabClick("/")}
                className={activeTab === "/" ? "font-bold text-[15px]" : ""}
              >
                Exchange
              </Link>
            </li>
            <li>
              <Link
                to="/insights"
                onClick={() => handleTabClick("/insights")}
                className={
                  activeTab === "/insights" ? "font-bold text-[15px]" : ""
                }
              >
                Insights
              </Link>
            </li>
            <li>
              <Link
                to="/news"
                onClick={() => handleTabClick("/news")}
                className={activeTab === "/news" ? "font-bold text-[15px]" : ""}
              >
                News
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                onClick={() => handleTabClick("/about")}
                className={
                  activeTab === "/about" ? "font-bold text-[15px]" : ""
                }
              >
                About Us
              </Link>
            </li>
          </ul>
        </div>

        {/* Buttons + Avatar */}
        {/* <div className="navbar-end flex gap-5 w-full md:w-1/2"> */}
        <div className="navbar-end flex flex-col items-end justify-around w-full md:w-1/2 text-[10px] sm:text-xs">
          {/* Notification */}
          {/* <button
            type="button"
            className="text-[#6886A2] rounded-full btn-ghost w-8 h-8 md:me-0"
          >
            <NotificationsIcon style={{ fontSize: "22px" }} />
          </button> */}

          {/* Avatar */}
          {/* <div className="dropdown dropdown-end">
            <div
              tabIndex={0}
              role="button"
              className="btn btn-ghost btn-circle avatar"
            >
              <div className="w-8 rounded-full">
                <img
                  alt="Tailwind CSS Navbar component"
                  src="./assets/avatar.svg"
                />
              </div>
            </div>
          </div> */}

          <p className="text-gray-600">{formattedDate}</p>
          <p className="text-gray-600">{formattedTime}</p>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
